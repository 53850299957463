body {
  background-color:rgb(255,255,255) !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(to right, rgb(21, 28, 88), rgb(89, 139, 247)) !important;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, rgb(21, 28, 88), rgb(89, 139, 247)) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.react-datepicker-wrapper {
  width: 100%;
}
/*end home page styles*/
/* background-image: linear-gradient(to right, rgb(95, 231, 179), pink) !important; */
/* background-image: linear-gradient(to right, rgb(37, 105, 29), rgb(81, 199, 68)) !important; */
/* background-image: linear-gradient(to right, rgb(21, 28, 88), rgb(89, 139, 247)) !important; */

