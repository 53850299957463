.auto-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.pos-rel {
    position: relative;
}

.autoContainer {
    position: absolute;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: brown;
    background-color: whitesmoke;
    left:0px;
    top: 38px;
    width: 500px;
    z-index: 999;
}

.option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    padding: 5px;
}

.option, span {
    font-size: 14px;
    text-transform: capitalize;
}

.flex-container {
    display: flex;
  }
  
.flex-column {
    flex-direction: column;
}